import { Vue } from 'vue-class-component'
import { useRoute } from 'vue-router'
import MembersDataService from '@/services/MembersDataService'
import SysMember, { SharePermissionType } from '@/types/SysMember'
import BaseFunctions, { defaultClubCaptainGroupId } from '@/components/Utility/Base'
import CommonFunctions from '@/components/Utility/Common'
import AuthService from '@/services/AuthService'

type dataReturnType = { clubMembers: any; }

export default class clubCaptains extends Vue {
  $Message: any
  private clubId = 0
  private clubCaptainId = this.parseURL()
  private memberSearchTerm = ''
  private memberSearchInputValue = ''
  private currentListSortingOrder = 'user_id.lastname:asc'
  private clubMembers: SysMember[] = []

  data (): dataReturnType {
    return {
      clubMembers: this.clubMembers
    }
  }

  public findMember () : void {
    this.memberSearchTerm = this.memberSearchInputValue
    this.retrieveClubMembers()
  }

  public convertToSlug (text: string) : string {
    return CommonFunctions.slugify(text)
  }

  public generateUserSlug (id: number, firstName: string, lastName: string) : string {
    return id.toString() + '-' + this.convertToSlug(firstName) + '_' + this.convertToSlug(lastName)
  }

  public showEmail (memberId: number) : string {
    let member = {} as SysMember
    const index = this.clubMembers.findIndex(el => el.id === memberId)
    if (index !== -1) {
      member = this.clubMembers[index]
    }
    if (member.user_id.email === null) {
      return 'Ingen email registreret'
    }
    if (member.medlem_visoplysninger === null || member.medlem_visoplysninger === SharePermissionType.Nej || member.medlem_visoplysninger === SharePermissionType.Telefonnummer) {
      return 'Ønsker ikke oplyst'
    }
    return member.user_id.email
  }

  public showPhoneNumber (memberId: number) : string {
    let member = {} as SysMember
    const index = this.clubMembers.findIndex(el => el.id === memberId)
    if (index !== -1) {
      member = this.clubMembers[index]
    }
    if (member.user_id.phone === null) {
      return 'Intet telefonnummber registreret'
    }
    if (member.medlem_visoplysninger === null || member.medlem_visoplysninger === SharePermissionType.Nej || member.medlem_visoplysninger === SharePermissionType.Email) {
      return 'Ønsker ikke oplyst'
    }
    return member.user_id.phone.toString()
  }

  public parseURL () : number {
    let retVal = 0
    const route = useRoute()
    const captainId = route.params.userSlug.toString().split('-')[0]

    if (Number(captainId) !== undefined) {
      retVal = Number(captainId)
    }
    return retVal
  }

  public retrieveClubMembers () : void {
    let captain: SysMember[] = []
    // Changed to use the userId from localStorage
    const userString = localStorage.getItem('user')?.toString()
    const userDataObject = (userString !== undefined && userString !== null ? JSON.parse(userString) : null)

    if (userDataObject === null || userDataObject.id === 1) {
      console.log('ERROR fetching memberData')
      CommonFunctions.redirectLogin()
      return
    }
    MembersDataService.getAll('', null, `user_id.id=${userDataObject.id}`)
      .then((response) => {
        captain = response.data

        if (captain.length !== 1) {
          this.$Message.danger({ text: 'Fejl: Noget gik galt' })
          return
        }
        // A rudementary check, if the memberId from the URL, does not match the memberId retrieved via the userId, logout and go to login page
        if (this.clubCaptainId !== Number(captain[0].id)) {
          AuthService.logout()
          CommonFunctions.redirectLogin()
          return
        }

        if (captain[0].user_id.usrgroup !== defaultClubCaptainGroupId) {
          this.$Message.danger({ text: 'Du har ikke rettigheder til at være her' })
          AuthService.logout()
          return this.$router.push({ name: 'Login', path: 'medlemsportal/login' })
        }
        this.clubId = Number(captain[0].klubber_id.id)
        if (this.memberSearchTerm.length > 0) {
          MembersDataService.findByNameAndLicenseSearchTerm(this.memberSearchTerm, this.currentListSortingOrder, null, `klubber_id.id=${this.clubId.toString()}`)
            .then((response) => {
              this.clubMembers = response.data
            })
            .catch((err) => {
              console.error(err)
            })
        } else {
          MembersDataService.getAll(this.currentListSortingOrder, null, `klubber_id.id=${this.clubId.toString()}`)
            .then((response) => {
              this.clubMembers = response.data
              console.log(this.clubMembers)
            })
            .catch((err) => {
              console.error(err)
            })
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }

  async mounted () : Promise<void> {
    const loggedInStatus = localStorage.getItem('status')
    const loginType = localStorage.getItem('logintype')
    const apiToken = localStorage.getItem('apitoken')
    const userdata = localStorage.getItem('user')

    if (loggedInStatus !== undefined && loggedInStatus !== null && loggedInStatus === true.toString() && loginType !== undefined && loginType !== null && loginType === true.toString() && apiToken !== undefined && apiToken !== null && apiToken.length >= 100 && userdata !== undefined && userdata !== null && !userdata.startsWith('{"id":1,')) {
      this.retrieveClubMembers()
    } else {
      CommonFunctions.redirectLogin()
    }
  }
}
