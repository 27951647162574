
import { Options, Vue } from 'vue-class-component'
import clubCaptains from '@/components/Memberportal/ClubCaptains/index.vue'

@Options({
  components: {
    clubCaptains
  }
})

export default class ClubCaptains extends Vue {}
